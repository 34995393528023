import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueCaptureSlider } from '@/valueCapture/ValueCaptureSlider.atom';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { ReportContentWorksheetAndUrl } from '@/reportEditor/components/reportContentModal/shared/ReportContentWorksheetAndUrl.molecule';
import { SelectWorksheet } from '@/worksheets/SelectWorksheet.molecule';
import { Button, Icon } from '@seeqdev/qomponents';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  availableStages,
  IMPACT,
  IMPACT_CATEGORY,
  IMPACT_DESCRIPTION,
  IMPACT_FREQUENCY,
  IMPACT_NAME,
  IMPACT_OWNER,
  IMPACT_SAVINGS_TYPE,
  IMPACT_STATUS,
  IMPACT_UNIT,
  ImpactType,
  stageIcons,
  Stages,
} from '@/valueCapture/valueCapture.types';
import { ConditionInputV1, sqConditionsApi, sqItemsApi, sqWorkbooksApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import * as models from 'sdk/model/models';
import { sqWorkbenchStore } from '@/core/core.stores';
import { SaveAndContinueFormBuilder } from '@/formbuilder/SaveAndContinueFormBuilder.page';
import { IdentityPreviewV1 } from 'sdk/model/IdentityPreviewV1';
import { errorToast } from '@/utilities/toast.utilities';
import { logError } from '@/utilities/logger';
import { stageDisplayStringByIndex } from './valueCapture.utilities';
import { setDisplayedTeamMemberId } from '@/valueCapture/valueCapture.actions';
import { useAsyncEffect } from 'rooks';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { FakeLink } from '@/core/FakeLink';

interface ValueCaptureModalProps {
  closeFn: () => void;
}

export const ValueCaptureForm: React.FunctionComponent<ValueCaptureModalProps> = ({ closeFn }) => {
  const { t } = useTranslation();

  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);
  const [workbookId, setWorkbookId] = useState<string>('');
  const [worksheetId, setWorksheetId] = useState('');
  const [currentStage, setCurrentStage] = useState<Stages | null>(null);
  const [stageIndex, setStageIndex] = useState(0);
  const [impactType, setImpactType] = useState<ImpactType>('oneTime');
  const [conditionId, setConditionId] = useState<string | null>(null);

  // we don't need a store but we need to store the values in local state to ensure we can persist them accross stages
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [owner, setOwner] = useState({
    id: sqWorkbenchStore.currentUser.id,
    name: sqWorkbenchStore.currentUser.name,
  });
  const [category, setCategory] = useState('');
  const [asset, setAsset] = useState('');
  const [selectUnit, setSelectUnit] = useState('');
  const [lessonsLearned, setLessonsLearned] = useState('');
  const [impactStartDate, setImpactStartDate] = useState<Date | null>(null);
  const [impactEndDate, setImpactEndDate] = useState<Date | null>(null);
  const [impactAmount, setImpactAmount] = useState('');
  const [quantifyAmount, setQuantifyAmount] = useState('100');
  const [impactFrequency, setImpactFrequency] = useState('');
  const [additionalLinks, setAdditionalLinks] = useState('');
  const [selectedWorksheetDisplayName, setSelectedWorksheetDisplayName] = useState('');

  useEffect(() => {
    setCurrentStage(availableStages[stageIndex]);
  }, [stageIndex]);

  useEffect(() => {
    setWorkbookId(stateParams?.workbookId);
    setWorksheetId(stateParams?.worksheetId);
  }, [stateParams]);

  const currentStageDisplayString = t(`VALUE_CAPTURE.${currentStage?.toUpperCase()}`);

  const sectionHeader = (stageIndex: number) => (
    <>
      <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader mt20">
        <Icon icon={stageIcons[stageIndex]} extraClassNames="mr10 ml5" />
        <h4>{stageDisplayStringByIndex(stageIndex)}</h4>
      </div>
      <hr />
    </>
  );

  useAsyncEffect(async () => {
    const workbookResponse = await sqWorkbooksApi.getWorkbook({ id: workbookId });
    const worksheetResponse = await sqWorkbooksApi.getWorksheet({ workbookId, worksheetId });
    setSelectedWorksheetDisplayName(`${workbookResponse.data?.name} - ${worksheetResponse.data?.name}`);
    if (workbookId && worksheetId) {
      setCurrentStage(availableStages[0]);
    }
  }, [worksheetId, workbookId]);

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'identify',
      includeIf: stageIndex >= 0,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'identifyHeader',
          children: sectionHeader(0),
        },
        {
          component: 'FormControlFormComponent',
          name: 'title',
          label: 'Title',
          value: title,
          required: true,
          onChange: (title) => {
            setTitle(title as string);
          },
          size: 'md',
          testId: 'title',
        },
        {
          component: 'TextAreaFormComponent',
          name: 'description',
          label: 'Description',
          extraClassNames: 'width-maximum',
          rows: 5,
          value: description,
          required: true,
          onChange: (description) => {
            setDescription(description as string);
          },
          size: 'md',
          testId: 'description',
          placeholder:
            'The Description field should clearly describe the technical problem or challenge your team is facing. This may include issues with systems, processes, or equipment. Be as specific as possible, focusing on the following points:\n' +
            '\n' +
            'Problem Overview: Provide a concise explanation of the issue you are trying to solve. Mention the system, process, or equipment involved, and explain the challenge (e.g., pressure fluctuations, overloads, nuisance alarms).\n' +
            '\n' +
            'Impact: Describe the potential or actual negative impact of the issue. This could include safety risks, financial costs, production delays, or regulatory concerns.\n' +
            '\n' +
            'Specific Technical Details: Include any technical metrics or specifications related to the issue (e.g., pressure thresholds, system overloads, number of alarms, temperature limits). Be as detailed as possible so the problem is clearly understood.\n' +
            '\n' +
            'Action Items: Outline the steps or actions that will be taken to address the technical challenge. Specify who is responsible for each action, deadlines, and any tools or resources required for resolution or implementation.',
        },
        {
          component: 'LabelFormComponent',
          name: 'ownerLabel',
          value: 'Use Case Owner',
          testId: 'ownerLabel',
          extraClassNames: '',
        },
        {
          component: 'SelectIdentityFormComponent',
          name: 'owner',
          idForLabel: 'useCaseOwner',
          label: 'Use Case Owner',
          extraClassNames: 'width-maximum mb25',
          value: owner,
          required: true,
          skipStore: true,
          startEditable: true,
          onChange: (selectedIdentity: Partial<IdentityPreviewV1>) => {
            setOwner(_.pick(selectedIdentity, ['id', 'name']) as IdentityPreviewV1);
          },
          testId: 'owner',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'categorize',
      includeIf: stageIndex >= 1,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'categoryHeader',
          children: sectionHeader(1),
        },
        {
          label: 'Category',
          component: 'SelectFormComponent',
          name: 'category',
          value: category,
          skipStore: true,
          required: true,
          options: [
            { value: 'a', label: 'cat a' },
            { value: 'b', label: 'cat b' },
          ],
          onChange: (selection) => {
            setCategory(selection as string);
          },
        },
        {
          component: 'FormControlFormComponent',
          name: 'asset',
          label: 'Asset Type',
          value: asset,
          required: true,
          onChange: (asset) => {
            setAsset(asset as string);
          },
          size: 'md',
          testId: 'title',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'quantify',
      includeIf: stageIndex >= 2,

      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'quantifyHeader',
          children: sectionHeader(2),
        },
        {
          component: 'TextAreaFormComponent',
          name: 'lessonsLearned',
          label: 'Lessons learned',
          extraClassNames: 'width-maximum',
          rows: 3,
          value: lessonsLearned,
          placeholder: 'Shamus text please :) ',
          skipStore: true,
          onChange: (lessons) => {
            setLessonsLearned(lessons as string);
          },
          size: 'md',
          testId: 'lessonsLearned',
        },
        {
          component: 'LabelFormComponent',
          name: 'quantifyLabel',
          value: 'How many units of X did you save? - better header? HELP :) ',
          testId: 'quantifyLabel',
        },
        {
          component: 'FormRow',
          name: 'quantifyRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              extraClassNames: 'width-200',
              component: 'SelectFormComponent',
              name: 'quanitifyAmount',
              value: quantifyAmount,
              required: true,
              options: [
                { value: '100', label: '100s' },
                { value: '1000', label: '1000s' },
                { value: '10000', label: '10.000s' },
                { value: '100000', label: '100.000s' },
                { value: '1000000', label: '1.000.000' },
              ],
              onChange: (selection) => {
                setQuantifyAmount(selection as string);
              },
            },
            {
              component: 'LabelFormComponent',
              name: 'categorizeLabel',
              value: 'of',
              testId: 'categorizeLabels',
              extraClassNames: 'ml5 mr5',
            },
            {
              component: 'SelectUnitFormComponent',
              name: 'quantifyUnit',
              value: selectUnit,
              onChange: (selection) => {
                setSelectUnit(selection as string);
              },
              required: true,
            },
          ],
        },
        {
          component: 'CheckboxFormComponent',
          name: 'oneTime',
          type: 'radio',
          value: impactType === 'oneTime',
          onChange: () => {
            impactType === 'oneTime' ? setImpactType('continuous') : setImpactType('oneTime');
          },
          checkboxLabel: 'one time impact',
          id: 'oneTime',
          testId: 'oneTime',
          skipStore: true,
        },
        {
          component: 'CheckboxFormComponent',
          name: 'continuous',
          type: 'radio',
          value: impactType === 'continuous',
          onChange: () => {
            impactType === 'continuous' ? setImpactType('oneTime') : setImpactType('continuous');
          },
          checkboxLabel: 'continuous impact',
          id: 'continuous',
          testId: 'continuous',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'oneTimeFormGroup',
      includeIf: impactType === 'oneTime' && stageIndex >= 2,
      components: [
        {
          component: 'FormRow',
          name: 'startEndRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'DatePickerFormComponent',
              name: 'impactStartDate',
              label: 'Start Date',
              value: impactStartDate,
              required: true,
              skipStore: true,
              onChange: (date: Date) => {
                setImpactStartDate(date);
              },
              size: 'md',
              testId: 'impactStartDate',
              wrapperClassNames: 'mr5',
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'continuous',
      includeIf: impactType === 'continuous' && stageIndex >= 2,
      components: [
        {
          component: 'FormRow',
          name: 'startEndRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'DatePickerFormComponent',
              name: 'impactStartDate',
              label: 'Start Date',
              value: impactStartDate,
              required: true,
              onChange: (date: Date) => {
                setImpactStartDate(date);
              },
              size: 'md',
              testId: 'impactStartDate',
              wrapperClassNames: 'mr5',
            },
            {
              component: 'DatePickerFormComponent',
              name: 'impactEndDate',
              label: 'End Date',
              value: impactEndDate,
              onChange: (date: Date) => {
                setImpactEndDate(date);
              },
              size: 'md',
              testId: 'impactEndDate',
              wrapperClassNames: 'ml5',
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'monetize',
      includeIf: stageIndex >= 3,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'monetizeHeader',
          children: sectionHeader(3),
        },
        {
          component: 'FormRow',
          name: 'monetizeFormRow',
          components: [
            {
              component: 'FormControlFormComponent',
              label: 'Amount in ($)',
              name: 'impactAmount',
              value: impactAmount,
              size: 'md',
              onChange: (amount) => {
                setImpactAmount(amount as string);
              },
              testId: 'impactAmount',
              wrapperClassNames: 'mr5',
            },
            {
              component: 'FormGroup',
              name: 'continuousImpact',
              includeIf: impactType === 'continuous',
              extraClassNames: 'ml5',
              components: [
                {
                  label: 'Frequency',
                  component: 'SelectFormComponent',
                  name: 'impactFrequency',
                  value: impactFrequency,
                  options: [
                    { value: 'a', label: 'daily' },
                    { value: 'b', label: 'monthly' },
                    { value: 'b', label: 'add the rest' },
                  ],
                  onChange: (frequency) => {
                    setImpactFrequency(frequency as string);
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'additionalLinks',
      includeIf: stageIndex >= 3,
      components: [
        {
          component: 'TextAreaFormComponent',
          name: 'additionalLinks',
          label: 'Additional links',
          extraClassNames: 'width-maximum',
          rows: 3,
          value: additionalLinks,
          placeholder: 'separate links with a semicolon',
          skipStore: true,
          onChange: (links) => {
            setAdditionalLinks(links as string);
          },
          size: 'md',
          testId: 'additionalLinks',
        },
      ],
    },
  ];

  const onFormSubmitCallback = async (values: any) => {
    const {
      title,
      description,
      category,
      selectUnit,
      impactStartDate,
      impactEndDate,
      impactAmount,
      impactFrequency,
      owner,
    } = values;
    // we need a dummy formula so we can successfully create the condition
    let formula =
      "condition(10y, capsule('1971-01-01T00:00:00.00Z'," +
      " '1971-01-01T00:00:00.00Z').setProperty('Impact Estimate', 0))";

    const startDate = impactStartDate && (impactStartDate as Date).toISOString();
    const endDate = impactEndDate && (impactEndDate as Date).toISOString();

    if (startDate && impactAmount) {
      if (impactType === 'continuous') {
        if (!impactEndDate) {
          formula = `(condition(10y,capsule('${startDate}', now())).setUncertainty(1h)).intersect(days().setProperty('Impact Estimate',${impactAmount}/30.5),keepProperties())`;
        } else {
          formula = `(condition(10y,capsule('${startDate}','${endDate}'))).intersect(past()).intersect(days().setProperty('Impact Estimate',${impactAmount}/30.5),keepProperties()).setUncertainty(1h)`;
        }
      } else if (impactType === 'oneTime') {
        formula = `condition(10y,capsule('${startDate}', '${startDate}').setProperty('Impact Estimate', ${impactAmount}))`;
      }
    }

    const properties: Array<models.ScalarPropertyV1> = [];
    const addIfNotEmpty = (name: string, value: string | null) => {
      if (value) {
        properties.push({ name, value });
      }
    };
    addIfNotEmpty(IMPACT_CATEGORY, category);
    addIfNotEmpty(IMPACT_SAVINGS_TYPE, impactType);
    addIfNotEmpty(IMPACT_NAME, title);
    addIfNotEmpty(IMPACT_DESCRIPTION, description);
    addIfNotEmpty(IMPACT_FREQUENCY, impactFrequency);
    addIfNotEmpty(IMPACT_UNIT, selectUnit);
    addIfNotEmpty(IMPACT_STATUS, currentStage);
    addIfNotEmpty(IMPACT_OWNER, owner?.id);
    addIfNotEmpty(IMPACT, impactAmount);

    // This is necessary as TS insists on maximumDuration but adding it will break things.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const conditionInput: ConditionInputV1 = {
      datasourceId: 'ImpactReportConditions',
      datasourceClass: 'Seeq Data Lab',
      formula,
      name: title ?? 'Impact Condition',
      description: description ?? '',
      properties,
      scopedTo: workbookId,
    };
    if (!conditionId) {
      try {
        const conditionCreationResponse = await sqConditionsApi.createCondition(conditionInput);
        setConditionId(conditionCreationResponse.data.id);
      } catch (error) {
        errorToast({ httpResponseOrError: error });
        return Promise.reject(error);
      }
    } else {
      try {
        await sqConditionsApi.updateExistingCondition(
          { ...conditionInput, replaceCapsuleProperties: true },
          {
            id: conditionId,
          },
        );
      } catch (error) {
        errorToast({ httpResponseOrError: error });
        return Promise.reject(error);
      }
    }
    return Promise.resolve();
  };
  return (
    <div className="flexRowContainer flexAlignCenter mb10 flexFill valueCapture">
      {workbookId && worksheetId && (
        <>
          <div className="flexColumnContainer width-maximum p15 flexAlignCenter mbn16 formSectionHeader">
            <span>Selected Worksheet:</span>
            <FakeLink onClick={() => window.open()} extraClassNames="ml10">
              {selectedWorksheetDisplayName}
            </FakeLink>
            <Icon
              icon="fa-close"
              onClick={() => {
                setWorkbookId('');
                setWorksheetId('');
                setCurrentStage(null);
              }}
              extraClassNames="ml10 cursorPointer"
            />
          </div>
          <hr />
        </>
      )}
      {currentStage && (
        <>
          <h3>Current Stage: {currentStageDisplayString}</h3>
          <ValueCaptureSlider setStageIdx={setStageIndex} stageIndex={stageIndex} />
        </>
      )}

      {!workbookId && !worksheetId && (
        <div className="flexRowContainer mt15 flexFill width-maximum flexAlignCenter">
          <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader mt20 width-maximum">
            <h4 className="ml10">Select a workbook and worksheet to proceed</h4>
          </div>
          <hr />
          <ReportContentWorksheetAndUrl
            setSelectedItem={(item) => {
              setWorkbookId(item?.id);
            }}
          />
          <Button label="Continue" disabled={!workbookId} extraClassNames="mt10" />
        </div>
      )}
      {workbookId && !worksheetId && (
        <div className="flexRowContainer mt15 flexFill flexAlignCenter">
          <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader mt20 width-maximum">
            <h4 className="ml10">Select a worksheet to proceed</h4>
          </div>
          <hr />
          <SelectWorksheet
            workbookId={workbookId}
            worksheetIds={[]}
            setWorksheetIds={(worksheetIds) => {
              // TODO: make this limit to only one
              if (worksheetIds?.length > 0) setWorksheetId(worksheetIds[0]);
            }}
            workstepIds={[]}
            setWorkstepIds={() => {}}
            setTotalWorksheets={() => {}}
            isLoading={false}
          />
          <Button label="Continue" disabled={!worksheetId} extraClassNames="mt10" />
        </div>
      )}
      {workbookId && worksheetId && (
        <>
          <SaveAndContinueFormBuilder
            submitBtnLabel="Save & Close"
            extraClassNames="flexFill"
            extraButtonLabel="Save & Continue"
            extraButtonPosition="left"
            extraButtonExtraClassNames="mr10"
            extraButtonEnabled={stageIndex < availableStages.length - 1}
            extraButtonOnSubmit={async (values) => {
              await onFormSubmitCallback(values);
              if (stageIndex < availableStages.length - 1) {
                setStageIndex(stageIndex + 1);
              }
            }}
            extraButtonVariant="theme"
            formDefinition={formDefinition}
            submitFn={async (values) => {
              await onFormSubmitCallback(values);
              closeFn();
            }}
            closeFn={closeFn}
          />
        </>
      )}
    </div>
  );
};
