export const IMPACT = 'impact';
export const IMPACT_NAME = 'Impact Condition Name';
export const IMPACT_DESCRIPTION = 'Impact Condition Description';
export const IMPACT_OWNER = 'Impact Condition Owner';
export const IMPACT_OWNER_FIRSTNAME = 'Impact Owner Firstname';
export const IMPACT_OWNER_LASTNAME = 'Impact Owner Lastname';
export const IS_MANAGER = 'isManager';
export const OWNER = 'owner';
export const IMPACT_OWNER_DISPLAY_NAME = 'Impact Owner Display Name';
export const MANAGER_ID = 'managerId';
export const USE_CASE_COUNT = 'useCaseCount';
export const IMPACT_STATUS = 'Impact Condition Status';
export const STATUS_PROPOSED = 'Proposed';
export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_COMPLETE = 'Complete';
export const IMPACT_DOCUMENT_LINK = 'Impact Condition Document Links';
export const IMPACT_FORMULA = 'Formula';
export const DENORMALIZED = 'denormalized';
export const IMPACT_CATEGORY = 'Impact Condition Category';
export const IMPACT_SAVINGS_TYPE = 'Impact Condition Savings Type';
export const IMPACT_FREQUENCY = 'Impact Condition Frequency';
export const IMPACT_UNIT = 'Impact Condition Unit';

export interface ValueCaptureUseCase {
  [IMPACT_OWNER]: string;
  [IMPACT_OWNER_FIRSTNAME]: string | undefined;
  [IMPACT_OWNER_LASTNAME]: string | undefined;
  [IS_MANAGER]?: boolean;
  [OWNER]?: string;
  [DENORMALIZED]?: string;
  [MANAGER_ID]?: string;
}

export interface ValueCaptureDetails {
  [IMPACT_NAME]: string;
  [IMPACT_DESCRIPTION]: string;
  [IMPACT_OWNER]: string;
  [IMPACT_OWNER_FIRSTNAME]: string | undefined;
  [IMPACT_OWNER_LASTNAME]: string | undefined;
  [IMPACT_OWNER_DISPLAY_NAME]: string;
  [IMPACT_STATUS]: string;
  [IMPACT_DOCUMENT_LINK]: string;
  [IMPACT_FORMULA]: string;
  [IMPACT_CATEGORY]: string;
  [IMPACT_SAVINGS_TYPE]: string;
  [IMPACT]: number;
}

export type Stages = 'identify' | 'categorize' | 'quantify' | 'monetize';
export type ImpactType = 'oneTime' | 'continuous';
export const availableStages: Stages[] = ['identify', 'categorize', 'quantify', 'monetize'];
export const stageIcons = ['fa-lightbulb-on', 'fa-list', 'fa-calculator', 'fa-piggy-bank'];
