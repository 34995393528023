import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@seeqdev/qomponents';
import { ButtonVariant } from '@seeqdev/qomponents/dist/Button/Button.types';
import { useTranslation } from 'react-i18next';

interface CancelAndSaveProps {
  submitFn?: (...values: any[]) => any;
  cancelFn?: () => void;
  values: any[];
  btnDisabled?: boolean;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  cancelClassNames?: string;
  submitClassNames?: string;
  cancelBtnTestId?: string;
  submitBtnTestId?: string;
  submitBtnVariant?: ButtonVariant;
  hideSubmit?: boolean;
  hideCancel?: boolean;
  // this is useful for those rare cases where the submit button should remain in a loading state after the submitFn is
  // resolved (ex. oauth)
  continueProcessing?: boolean;
  extraButtonLabel?: string;
  extraButtonVariant?: ButtonVariant;
  extraButtonOnSubmit?: (values: any) => void;
  extraButtonPosition?: 'left' | 'right';
  extraButtonExtraClassNames?: string;
  extraButtonEnabled?: boolean;
}

/**
 * Cancel and Save buttons
 */
export const CancelAndSave: React.FunctionComponent<CancelAndSaveProps> = (props) => {
  const {
    submitFn,
    cancelFn,
    btnDisabled,
    values,
    cancelBtnLabel = 'CANCEL',
    submitBtnLabel,
    cancelClassNames = '',
    submitClassNames = '',
    cancelBtnTestId = 'cancelButton',
    submitBtnTestId = 'executeButton',
    submitBtnVariant = 'theme',
    hideSubmit,
    hideCancel,
    continueProcessing,
    extraButtonLabel,
    extraButtonVariant,
    extraButtonOnSubmit,
    extraButtonPosition,
    extraButtonExtraClassNames,
    extraButtonEnabled,
  } = props;

  const [processing, setProcessing] = useState(false);
  const isClosed = useRef(false);
  const { t } = useTranslation();

  useEffect(
    () => () => {
      isClosed.current = true;
    },
    [],
  );

  const manageSubmission = async (extraButtonOnSubmit: null | any) => {
    setProcessing(true);
    return Promise.resolve()
      .then(() => (extraButtonOnSubmit ? extraButtonOnSubmit(values) : submitFn?.(values)))
      .finally(() => !isClosed.current && setProcessing(false));
  };

  return (
    <>
      {!hideCancel && (
        <Button
          onClick={() => cancelFn?.()}
          label={t(cancelBtnLabel)}
          size="sm"
          testId={cancelBtnTestId}
          extraClassNames={cancelClassNames || 'mr20 min-width-100 width-100 max-width-100'}
        />
      )}
      {extraButtonLabel && extraButtonPosition === 'left' && (
        <Button
          id="extraSave"
          type="submit"
          onClick={() => manageSubmission(extraButtonOnSubmit)}
          label={t(extraButtonLabel)}
          disabled={!extraButtonEnabled || processing || continueProcessing || btnDisabled}
          size="sm"
          icon={processing || continueProcessing ? 'fa-spinner fa-spin-pulse fa-lg sq-icon-white mr5' : ''}
          iconPrefix="fa-solid"
          testId="extraButtonTestId"
          extraClassNames={extraButtonExtraClassNames || 'min-width-100'}
          variant={extraButtonVariant}
        />
      )}

      {!hideSubmit && (
        <Button
          id="save"
          type="submit"
          onClick={() => manageSubmission(null)}
          label={submitBtnLabel ? t(submitBtnLabel) : t('SAVE')}
          disabled={btnDisabled || processing || continueProcessing}
          size="sm"
          icon={processing || continueProcessing ? 'fa-spinner fa-spin-pulse fa-lg sq-icon-white mr5' : ''}
          iconPrefix="fa-solid"
          testId={submitBtnTestId}
          extraClassNames={submitClassNames || 'min-width-100'}
          variant={submitBtnVariant}
        />
      )}
      {extraButtonLabel && extraButtonPosition === 'right' && (
        <Button
          id="extraSave"
          type="submit"
          onClick={() => manageSubmission(extraButtonOnSubmit)}
          label={t(extraButtonLabel)}
          disabled={!extraButtonEnabled || processing || continueProcessing || btnDisabled}
          size="sm"
          icon={processing || continueProcessing ? 'fa-spinner fa-spin-pulse fa-lg sq-icon-white mr5' : ''}
          iconPrefix="fa-solid"
          testId="extraButtonTestId"
          extraClassNames={extraButtonExtraClassNames || 'min-width-100'}
          variant={extraButtonVariant}
        />
      )}
    </>
  );
};
