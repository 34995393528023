// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { TextArea, TextField } from '@seeqdev/qomponents';
import { setValueCaptureEndDate } from '@/valueCapture/valueCapture.actions';
import { getDisplayDate } from '@/valueCapture/valueCapture.utilities';
import DatePicker from 'react-datepicker';

export interface DatePickerFormComponentIF extends ValidatingFormComponent<string | number> {
  component: 'DatePickerFormComponent';
  id?: string;
  size: string;
  placeholder?: string;
  onChange: (date: any) => void;
  as?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onKeyDown?: (key: string, value: string) => void;
  disabled?: boolean;
  skipMemo?: boolean;
  autoFocus?: boolean;
  displayError?: boolean;
  customErrorText?: string;
  labelIcon?: string;
  labelIconAction?: () => void;
  labelIconTooltip?: string;
  labelClassNames?: string;
  value: any;
}

export const DatePickerFormComponent: React.FunctionComponent<DatePickerFormComponentIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    testId = 'formControl',
    required,
    customErrorText,
    labelIcon,
    labelIconAction,
    labelIconTooltip,
    labelClassNames,
  } = props;

  const { t } = useTranslation();

  const defaultValidation = (value: string) => required && _.isEmpty(_.trim(value));

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    (formState.modified[name] || formState.submitFailed);

  return (
    <FormFieldWrapper
      {...getFormFieldWrapperProps(props, ['id'])}
      testId={`${testId}-wrap`}
      labelIcon={labelIcon}
      labelIconAction={labelIconAction}
      labelIconTooltip={labelIconTooltip}
      labelClassNames={labelClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
            placeholder: t(placeholder),
            value: input.value,
            onChange: (date: Date | null) => {
              input.onChange(date);
              onChange?.(date);
            },
            onFocus: (e) => e.target.select(),
          });

          return (
            <DatePicker
              portalId="datepicker-portal"
              {...properProps}
              date={input.value}
              customInput={
                <div>
                  <TextField extraClassNames="calendarInput ml5" value={getDisplayDate(input.value)} />
                </div>
              }
              required={properProps.showError && properProps.required}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
