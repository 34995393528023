import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import { IdentityPreviewV1 } from 'sdk/model/IdentityPreviewV1';

export type viewType = 'team' | 'useCase';
interface ValueCaptureState {
  view: viewType;
  selectedUserId: string | null;
  manager: IdentityPreviewV1 | null;
  startDate: Date | null;
  endDate: Date | null;
  displayedTeamMemberId: string | null;
}

export class ValueCaptureStore extends Store {
  static readonly storeName = 'sqValueCaptureStore';
  persistenceLevel: PersistenceLevel = 'NONE';

  get startDate(): Date | undefined {
    return this.state.get('startDate');
  }

  get endDate(): Date | undefined {
    return this.state.get('endDate');
  }

  get view(): viewType {
    return this.state.get('view');
  }

  get manager(): IdentityPreviewV1 | null {
    return this.state.get('manager');
  }

  get managerId(): string | null {
    return this.state.get('managerId');
  }

  get selectedUserId(): string | null {
    return this.state.get('selectedUserId');
  }

  get displayedTeamMemberId(): string | null {
    return this.state.get('displayedTeamMemberId');
  }

  initialize(initializeMode: InitializeMode) {
    this.state = this.immutable({
      view: 'team',
      selectedUserId: null,
      manager: null,
      displayedTeamMemberId: null,
      startDate: null,
      endDate: null,
    });
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: ValueCaptureState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    VALUE_CAPTURE_VIEW: (payload: { view: viewType }) => {
      this.state.set('view', payload.view);
    },
    VALUE_CAPTURE_SET_MANAGER: (payload: { manager: IdentityPreviewV1 }) => {
      this.state.set('manager', payload.manager);
    },

    VALUE_CAPTURE_SET_MANAGER_ID: (payload: { managerId: string }) => {
      this.state.set('managerId', payload.managerId);
    },
    VALUE_CAPTURE_SET_SELECTED_USER_ID: (payload: { selectedUserId: string }) => {
      this.state.set('selectedUserId', payload.selectedUserId);
    },

    VALUE_CAPTURE_SET_START_DATE: (payload: { startDate: Date }) => {
      this.state.set('startDate', payload.startDate);
    },

    VALUE_CAPTURE_SET_END_DATE: (payload: { endDate: Date }) => {
      this.state.set('endDate', payload.endDate);
    },

    VALUE_CAPTURE_SET_DISPLAYED_TEAM_MEMBER_ID: (payload: { displayedTeamMemberId: string }) => {
      this.state.set('displayedTeamMemberId', payload.displayedTeamMemberId);
    },
  };
}
