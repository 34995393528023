import { UserOutputListV1 } from 'sdk/model/UserOutputListV1';
import { sqFormulasApi, sqUsersApi } from '@/sdk';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { formatDate } from '@/vantage/dateTime.utilities';
import {
  availableStages,
  DENORMALIZED,
  IMPACT,
  IMPACT_FORMULA,
  IMPACT_OWNER,
  IMPACT_OWNER_FIRSTNAME,
  IMPACT_OWNER_LASTNAME,
  IMPACT_STATUS,
  IS_MANAGER,
  MANAGER_ID,
  OWNER,
  stageIcons,
  Stages,
  ValueCaptureUseCase,
} from '@/valueCapture/valueCapture.types';
import { ValueFormatterParams } from '@ag-grid-community/core/dist/types/src/entities/colDef';
import { UserOutputV1 } from 'sdk/model/UserOutputV1';
import { t } from 'i18next';

export const VALUE_CAPTURE_DATE_FORMAT = 'MMM-DD-YYYY';

export const TABLE_NAME = 'TESTTABLE03';
export async function getTeam(userId: string) {
  const apiResponse: AxiosResponse<UserOutputListV1, any> = await sqUsersApi.getUsers({ manager: userId });
  return apiResponse.data.users;
}

export function getDateRangeOptions() {
  return [
    {
      label: 'VALUE_CAPTURE.DATE_RANGE_OPTIONS.TODAY',
      startDate: moment().subtract(1, 'week').toDate(),
    },
    {
      label: 'VALUE_CAPTURE.DATE_RANGE_OPTIONS.LAST_MONTH',
      startDate: moment().subtract(1, 'month').toDate(),
    },
    {
      label: 'VALUE_CAPTURE.DATE_RANGE_OPTIONS.LAST_QUARTER',
      startDate: moment().subtract(1, 'quarter').toDate(),
    },
    {
      label: 'VALUE_CAPTURE.DATE_RANGE_OPTIONS.LAST_YEAR',
      startDate: moment().subtract(1, 'year').toDate(),
    },
    {
      label: 'VALUE_CAPTURE.DATE_RANGE_OPTIONS.YEAR_TO_DATE',
      startDate: moment().startOf('year').toDate(),
    },
  ];
}

export function getDisplayDate(date: Date | undefined) {
  return date ? formatDate(date!, VALUE_CAPTURE_DATE_FORMAT) : '';
}

export function getCalendarRangeDateLabel(startDate: Date | undefined, endDate: Date | undefined) {
  return `${getDisplayDate(startDate)} - ${getDisplayDate(endDate)}`;
}

/**
 * The impact is calculated based on a Formula that is stored on the use case.
 */
export async function decorateMaterializedTableWithImpactValue(tableData: any, startDate: Date, endDate: Date) {
  return await Promise.all(
    tableData.map(async (row: any) => {
      try {
        const formula = row[IMPACT_FORMULA];
        const formulaResponse = await sqFormulasApi.runFormula({
          // TODO: Shamus send me new formula with $condition.toSignal('Impact
          //  Estimate').sum(capsule('2021-01-01T00:00:00Z','2021-12-31T23:59:59Z'))
          formula: `(${formula}).toSignal('Impact Estimate').sum(capsule('${startDate?.toISOString()}','${endDate?.toISOString()}'))`,
        });
        const impact: number = formulaResponse.data?.scalar?.value; //
        row[IMPACT] = impact;
      } catch (error) {
        console.log(error);
      }
      return row;
    }),
  );
}

/**
 * In order for AGGrid to be able to rollup the data as needed we need to denormalize the table.
 * "Denormalizing" the table effectively adds new rows that will match the queries that AGGrid performs.
 *
 * In this scenario we need to duplicate all the useCase rows so that when we query by a top level manager all the
 * use cases of their reports (and their reports ...) are included.
 *
 */
export async function deNormalizeTable(tableData: ValueCaptureUseCase[]) {
  const denormalizedTable: ValueCaptureUseCase[] = [];

  const denormalizeIt: any = async (row: ValueCaptureUseCase) => {
    const entry = { ...row };
    if (!entry[IMPACT_OWNER]) {
      return;
    }
    if (!entry[DENORMALIZED]) {
      if (entry[IS_MANAGER]) {
        // if the user case owner is a manager
        denormalizedTable.push({
          ...entry,
          [OWNER]: entry[IMPACT_OWNER],
          [MANAGER_ID]: entry[IMPACT_OWNER],
          [DENORMALIZED]: 'yes',
          [IS_MANAGER]: true,
        });
      }

      denormalizedTable.push({ ...entry, owner: entry[IMPACT_OWNER], [DENORMALIZED]: 'no' }); // add existing use case
    }

    // If the use case has a manager then
    //    - create a copy of that use case
    //    - make the manager the owner (update ALL the owner associated field)
    //    - determine the new manager by looking up the manager of the manager

    if (entry.managerId) {
      const { data } = await sqUsersApi.getUser({ id: entry[MANAGER_ID] }); // get the manager of the manager
      // originally assigned to the use case
      if (data.manager?.id) {
        const normalizedEntry: ValueCaptureUseCase = {
          ...entry,
          [MANAGER_ID]: data.manager?.id,
          [IS_MANAGER]: true,
          [DENORMALIZED]: 'yes',
          [IMPACT_OWNER]: entry.managerId,
          [IMPACT_OWNER_FIRSTNAME]: data.firstName,
          [IMPACT_OWNER_LASTNAME]: data.lastName,
          [OWNER]: entry.managerId,
        };
        denormalizedTable.push(normalizedEntry);
        return denormalizeIt(normalizedEntry);
      }
    }
  };

  await Promise.all(tableData.map((row: ValueCaptureUseCase) => denormalizeIt(row)));

  return denormalizedTable;
}

export async function getAllTeamMembers(startingManagerId: string) {
  let teamMembers: UserOutputV1[] = [];
  const getTeam = async (managerId: string) => {
    const userResponse = await sqUsersApi.getUsers({ manager: managerId });
    const users = userResponse.data.users;
    if (!users) return;
    teamMembers = [...teamMembers, ...users];
    await Promise.all(
      users?.map(async (user) => {
        if (user.isManager) return getTeam(user.id);
      }),
    );
  };
  await getTeam(startingManagerId);
  console.log(teamMembers);
  return teamMembers;
}

export function formatNumberAsValue(num: number): string {
  if (num >= 1_000_000) {
    return `$${(num / 1_000_000).toFixed(3)}M`;
  } else if (num >= 1_000) {
    return `$${(num / 1_000).toFixed(0)}K`;
  } else {
    return `$${new Intl.NumberFormat('en-US').format(Math.round(num))}`;
  }
}

export function getImpactValueCell(title: string, status: string, valueGetter?: (rowData: any) => number) {
  return {
    field: status,
    headerName: title,
    valueFormatter: (params: ValueFormatterParams) => formatNumberAsValue(params.value),
    cellClass: 'right-align',
    headerClass: 'pull-right',
    valueGetter: valueGetter
      ? valueGetter
      : (rowData: any) => {
          if (!rowData || !rowData.data) return;
          if (rowData.node.rowPinned === 'bottom') return rowData.data[status];
          return rowData?.data[IMPACT_STATUS] === status ? rowData.data.impact : 0;
        },
    aggFunc: 'sum',
    flex: 1,
  };
}

export function stageDisplayStringByIndex(stageIndex: number) {
  return t(`VALUE_CAPTURE.${availableStages[stageIndex]?.toUpperCase()}`);
}

export function getImpactStageIcon(stage: Stages) {
  const index = availableStages.indexOf(stage);
  if (index > -1 && index <= stageIcons.length) {
    return stageIcons[availableStages.indexOf(stage)];
  } else {
    return stageIcons[0];
  }
}
