import React, { useEffect, useRef, useState } from 'react';
import { Img } from 'react-image';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { onThumbnail } from '@/services/notifier.service';
import { DEBOUNCE } from '@/core/core.constants';
import classNames from 'classnames';
import { getPresentWorksheetLink, getShortViewWorksheetLink, getWorksheetLink } from '@/main/routing.utilities';
import { isEditWorkbookMode, isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { WrapperWithLink } from '@/core/WrapperWithLink.atom';

interface WorksheetThumbnailProps {
  worksheetId: string;
  workbookId: string;
  isActive: boolean;
  updatedAt?: string;
  onClick?: () => void;
}

const getThumbnailLink = (workbookId: string, worksheetId: string) => {
  if (isEditWorkbookMode()) {
    return getWorksheetLink(workbookId, worksheetId, undefined, undefined, true);
  } else if (isViewOnlyWorkbookMode()) {
    return getShortViewWorksheetLink(worksheetId, true);
  } else {
    return getPresentWorksheetLink(workbookId, worksheetId, true);
  }
};

export const WorksheetThumbnail: React.FunctionComponent<WorksheetThumbnailProps> = ({
  worksheetId,
  workbookId,
  isActive,
  updatedAt,
  onClick,
}) => {
  const [randomId, setRandomId] = useState(Math.random());
  const [showSpinner, setShowSpinner] = useState(false);
  const thumbnailLink = getThumbnailLink(workbookId, worksheetId);
  const thumbnailRef = useRef<HTMLDivElement>(null);

  // Only show the spinner if it's taking longer than 1 second to load the thumbnail
  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), DEBOUNCE.LONG);
    return () => clearTimeout(timer);
  });

  const loadingPlaceholder = (showSpinner: boolean) => (
    <div className="width-100 height-80 text-center lightGreyBorder">
      {showSpinner && (
        <div className="mt35">
          <IconWithSpinner spinning={true} />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    return onThumbnail((updatedWorkbookId: string, updatedWorksheetId: string) => {
      if (updatedWorkbookId === workbookId && updatedWorksheetId === worksheetId) {
        setRandomId(Math.random());
      }
    });
  }, [workbookId, worksheetId]);

  useEffect(() => {
    if (isActive && thumbnailRef.current) {
      const container = document.getElementById('worksheetScrollArea');

      if (container && thumbnailRef.current) {
        const containerRect = container.getBoundingClientRect();
        const thumbnailRect = thumbnailRef.current.getBoundingClientRect();
        const offset = thumbnailRect.top - containerRect.top;
        container.scrollTo({ top: container.scrollTop + offset });
      }
    }
  }, []);

  return (
    <div
      ref={thumbnailRef}
      onClick={onClick}
      data-testid={`${worksheetId}_thumbnailContainer`}
      className="cursorPointer flexRowContainer flexAlignCenter">
      <WrapperWithLink link={thumbnailLink}>
        <Img
          id={`${worksheetId}_img`}
          data-test-id={`${worksheetId}_img`}
          className={classNames('p3 screenshotBackground', isActive ? 'activeWorksheetBorder' : 'darkGreyBorder')}
          src={`/thumbnails/${workbookId}_${worksheetId}.png?t=${updatedAt}&random=${randomId}`}
          alt={`${worksheetId}_img`}
          width={100}
          height={80}
          loader={loadingPlaceholder(showSpinner)}
          unloader={loadingPlaceholder(false)}
        />
      </WrapperWithLink>
    </div>
  );
};
