import React, { forwardRef } from 'react';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { FormBuilder } from '@/formbuilder/FormBuilder.page';
import { ButtonVariant } from '@seeqdev/qomponents/dist/Button/Button.types';

interface SaveAndContinueFormBuilder {
  testId?: string;
  // the formDefinition Array of FormElements defines the form to be rendered
  formDefinition: FormElement[];
  // the function that is called when the form is submitted
  submitFn: (values: any, fromSubmitButton?: boolean) => void;
  // the function that is called when the cancel button is clicked
  closeFn: () => any;
  // if debug is enabled then the form values and form errors are rendered below this form;
  debug?: boolean;
  // option to have the submit button say something other than 'Save'
  submitBtnLabel?: string;
  // option to have different Save or Execute submit button
  submitBtnVariant?: ButtonVariant;
  // option to hide the submit button
  hideSubmit?: boolean;
  // option to hide the cancel button
  hideCancel?: boolean;
  // option to have the cancel button say something other than 'Cancel'
  cancelBtnLabel?: string;
  // extra class-names for action buttons
  buttonExtraClassNames?: string;
  // this is useful for those rare cases where the submit button should remain in a loading state after the submitFn is
  // resolved (ex. oauth)
  continueProcessing?: boolean;
  // option to pass a ref to access submit button status
  ref?: any;
  extraClassNames?: string;
  extraButtonLabel: string;
  extraButtonEnabled?: boolean;
  extraButtonVariant: ButtonVariant;
  extraButtonOnSubmit: (values: any) => void;
  extraButtonPosition?: 'left' | 'right';
  extraButtonExtraClassNames?: string;
  submitButtonOnSubmit?: (values: any) => void;
}

/**
 * SimpleSaveFormBuilder generates forms based on the provided form definition Array.
 * SimpleSaveFormBuilder is NOT TO BE USED for tools - use ToolFormBuilder for tools. SimpleSaveFormBuilder should
 * be used for forms that only display a save/cancel button, and do not need tool specific tracking.
 **/
export const SaveAndContinueFormBuilder: React.FunctionComponent<SaveAndContinueFormBuilder> = forwardRef(
  function SaveAndContinueFormBuilder(props, ref) {
    return <FormBuilder {...props} ref={ref} saveAndCancel={true} extraClassNamesUnwrapped={props.extraClassNames} />;
  },
);
